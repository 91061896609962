import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/Layout';
import SocialLinks from '../components/SocialLinks';

import * as S from '../components/Post/style';

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <S.PostHeader>
      <S.PostTitle>About Me</S.PostTitle>
    </S.PostHeader>
    <S.MainContent>
      <p>
        {`My name is Eber Martins Rodrigues and I have several years of experience as a back-end developer working both in consulting firms and in product companies. `}
        {`My main specialties are Java and AWS, but I occasionally play with and discuss front-end techs. `}
        {`My hobbies include chess, genealogy, and numismatics, but I indeed enjoy the simple things of life, such as a stroll in the park.`}
      </p>
      <p>{`Please feel free to reach out if you like!`}</p>
      <p
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >{`eberjoe@gmail.com`}</p>
    </S.MainContent>
    <SocialLinks insist />
  </Layout>
);

export default AboutPage;
